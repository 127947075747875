import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import REQUEST_METHOD from "@/constants/request-method";
import urlJoin from "url-join";

export const getListSearchHistory = async (
  header?: any
) => {
  const path = urlJoin(`v1/projects/search-history`);

  const response = await createFetcher<any>(
    () => ({
      apiUrl: MEEY_REVIEW_API_URL,
    })
  )(REQUEST_METHOD.GET, path, null, header);

  return response;
};
