import Button from "@/components/Button";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { ValueLocation } from "@/models/location";
import { isNumeric } from "@/utils/number";
import axios from "axios";
import clsx from "clsx";
import { unionBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/* eslint-disable @next/next/no-img-element */
const SelectCity = ({
  valueLocation,
  setValueLocation,
  setActiveTab,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
  onConfirm?: Dispatch<SetStateAction<ValueLocation>>;
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(valueLocation.district);
  }, [valueLocation]);

  const listDataRender = useMemo(() => {
    return unionBy(value, data, "_id").filter((e) =>
      e.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search, value]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.request({
        baseURL: MEEY_REVIEW_API_URL,
        url: "/v1/districts",
        method: "post",
        data: {
          limit: 500,
          city: valueLocation?.city?.[0]._id,
        },
      });
      setData(res.data?.results);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [valueLocation?.city]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className="flex gap-1 items-center mx-4 px-3 py-2 bg-[rgba(0,0,0,0.05)] rounded">
        <i className="mrv mrv-search text-fs-20" />
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value?.slice(0, 100))}
          placeholder="Tìm Quận/Huyện"
          className="h-[28px] mr-5 w-full text-base bg-transparent"
        />
      </div>

      {loading ? (
        <div className="w-full h-full flex justify-center py-4">
          <div className={"dot-flashing"}></div>
        </div>
      ) : (
        <>
          <div className="w-full h-full overflow-auto">
            {listDataRender?.map((district) => {
              const isSelect = value.find((item) => item._id === district._id);
              return (
                <div
                  key={district._id}
                  onClick={() => {
                    setValue(
                      isSelect
                        ? value?.filter((item) => item._id !== district._id)
                        : [...value, district]
                    );
                  }}
                  className="py-3 px-4 flex items-center  duration-300 cursor-pointer border-b border-b-grey-200 ">
                  <i
                    className={clsx(
                      "mrv   text-fs-24 mr-2",
                      isSelect
                        ? "mrv-check_box text-blue-500"
                        : "mrv-check_box_outline_blank"
                    )}></i>
                  {isNumeric(district?.name) ? district.title : district?.name}
                </div>
              );
            })}
          </div>

          <div className="flex py-2 px-3">
            <Button
              className="w-full flex justify-center !text-grey-600"
              btnType="link"
              onClick={() => {
                setValue([]);
              }}>
              Đặt lại{" "}
            </Button>
            <Button
              className="w-full flex justify-center"
              btnType="filled"
              onClick={() => {
                setValueLocation({
                  city: valueLocation.city,
                  district: value,
                  ward: [],
                });
                if (onConfirm)
                  onConfirm({
                    city: valueLocation.city,
                    district: value,
                    ward: [],
                  });
                else if (value.length) setActiveTab("ward");
              }}>
              Xác nhận{" "}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SelectCity;
