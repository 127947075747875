import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import NotificationPopupContent from "./NotificationPopupContent";
import {
  lockScroll,
  unlockScroll,
} from "@/vendor/scroll-locker/useScrollLocker";
import clsx from "clsx";

const NotiMobile = () => {
  const { token, user } = useAuth();
  const [countNoti, setCountNoti] = useState(0);
  const [open, setOpen] = useState(false);
  const seenAllNoti = useCallback(async () => {
    if (token)
      try {
        await axios.request({
          method: "POST",
          url: "/v1/notifications/view",
          baseURL: MEEY_REVIEW_API_URL,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountNoti(0);
      } catch (error) {}
  }, [token]);

  useEffect(() => {
    if (token)
      axios
        .request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/notifications/count",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCountNoti(res.data.count);
        });
  }, [token]);

  useEffect(() => {
    if (countNoti && open) seenAllNoti();
  }, [open, seenAllNoti, countNoti]);

  useEffect(() => {
    if (open) lockScroll();
    else unlockScroll();
    return () => {
      unlockScroll();
    };
  }, [open]);
  return user ? (
    <>
      <div
        className={clsx(
          "fixed w-screen h-screen  top-0 bg-white z-20 duration-300",
          open ? "left-0 " : "left-full"
        )}>
        {open ? (
          <NotificationPopupContent
            titleClassName="!text-fs-20"
            allowBack
            onClose={() => setOpen(false)}
            onBack={() => setOpen(false)}
          />
        ) : null}
      </div>
      <div
        className="rounded-full bg-blue-50 w-9 h-9 flex items-center justify-center relative cursor-pointer"
        onClick={() => setOpen(true)}>
        <i className="mrv mrv-notifications text-fs-20 text-blue-500"></i>
        {countNoti ? (
          <div className="absolute top-0 -right-2 text-white bg-red-500 rounded-full text-fs-12 px-1">
            {countNoti}
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

export default NotiMobile;
