import { ValueLocation } from "@/models/location";
import clsx from "clsx";
import { Dispatch, SetStateAction, cloneElement, useMemo } from "react";
import SelectCity from "./SelectCity";
import SelectDistrict from "./SelectDistrict";
import SelectWard from "./SelectWard";

/* eslint-disable @next/next/no-img-element */
const tabs = [
  {
    label: "Tỉnh/Thành phố",
    key: "city",
    child: <SelectCity />,
  },
  {
    label: "Quận/Huyện",
    key: "district",
    child: <SelectDistrict />,
  },
  {
    label: "Phường/Xã",
    key: "ward",
    child: <SelectWard />,
  },
];
const PopupSelectLocation = ({
  valueLocation,
  setValueLocation,
  onConfirm,
  activeTab,
  setActiveTab,
}: {
  valueLocation?: ValueLocation;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setValueLocation?: (value: ValueLocation) => void;
  onConfirm?: (value: ValueLocation) => void;
}) => {
  // const

  const activeItem = useMemo(() => {
    return tabs.find((tab) => tab.key === activeTab);
  }, [activeTab]);
  return (
    <>
      <div className="flex relative">
        <div
          className={clsx(
            "px-4 py-3 font-medium cursor-pointer  bg-grey-50 duration-300 text-black flex justify-center w-full rounded-t-[1.5rem]"
          )}>
          {activeItem?.label}
        </div>
        <div
          className="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer"
          onClick={() => setActiveTab(null)}>
          <i className="mrv mrv-close text-fs-24 font-medium" />
        </div>
      </div>
      <div className="h-[30rem] pt-3 overflow-auto flex flex-col ">
        {activeItem
          ? cloneElement(activeItem?.child, {
              valueLocation,
              setValueLocation,
              setActiveTab,
              onConfirm,
            })
          : null}
      </div>
    </>
  );
};

export default PopupSelectLocation;
