import Button from "@/components/Button";
import { TIMEOUT_LOAD_SEARCH } from "@/constants/common-constant";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { ValueLocation } from "@/models/location";
import { isNumeric } from "@/utils/number";
import axios from "axios";
import clsx from "clsx";
import { unionBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

/* eslint-disable @next/next/no-img-element */
const SelectCity = ({
  valueLocation,
  setValueLocation,
  setActiveTab,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
  onConfirm?: (value: ValueLocation) => void;
}) => {
  const [data, setData] = useState({
    results: [],
    totalResults: 0,
    totalPages: 0,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(valueLocation.ward);
  }, [valueLocation]);

  const listDataRender = useMemo(() => {
    return search?.length
      ? data.results
      : unionBy(valueLocation.ward, data.results, "_id");
  }, [data.results, search, valueLocation.ward]);

  const getData = useCallback(
    async (params?: any) => {
      setLoading(true);
      try {
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/wards",
          method: "post",
          data: {
            limit: 10,
            page: 1,
            name: search,
            sort: "name:asc",
            district: valueLocation.district?.map((e) => e._id),
            ...params,
          },
        });
        setData({
          ...res.data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [search, valueLocation.district]
  );

  const loadMoreData = useCallback(
    async (page?: number) => {
      setLoading(true);
      try {
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/wards",
          method: "post",
          data: {
            limit: 10,
            page: page ?? 1,
            name: search,
            sort: "name:asc",
            district: valueLocation.district?.map((e) => e._id),
          },
        });
        setData({
          ...res.data,
          results: page
            ? [...data.results, ...res.data.results]
            : res.data.results,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [data.results, search, valueLocation.district]
  );
  useEffect(() => {
    getData();
  }, []);
  const timeoutSearch = useRef(null);

  return (
    <>
      <div className="flex gap-1 items-center mx-4 px-3 py-2 bg-[rgba(0,0,0,0.05)] rounded">
        <i className="mrv mrv-search text-fs-20" />
        <input
          value={search}
          onChange={(e) => {
            clearTimeout(timeoutSearch.current);
            setSearch(e.target.value?.slice(0, 100));
            if (!loading)
              timeoutSearch.current = setTimeout(() => {
                getData({
                  name: e.target.value?.slice(0, 100),
                });
              }, TIMEOUT_LOAD_SEARCH);
          }}
          onKeyUp={(e: any) => {
            if (e.key === "Enter" && !loading) {
              clearTimeout(timeoutSearch.current);

              getData({
                name: e.target.value?.slice(0, 100),
              });
            }
          }}
          placeholder="Tìm Phường/Xã"
          className="h-[28px] mr-5 w-full text-base bg-transparent"
        />
      </div>

      {
        <>
          <div
            className={clsx(
              "w-full h-full overflow-auto px-3"
              // allowConfirm ? "mb-0" : "mb-3"
            )}
            id="scrollablePopup">
            <InfiniteScroll
              dataLength={data.results.length || 0}
              hasMore={data.page < data.totalPages}
              next={() => {
                loadMoreData(data.page + 1);
              }}
              scrollableTarget="scrollablePopup"
              className=" md:gap-4 gap-1  overflow-auto w-full"
              loader={
                <div className="px-4 flex items-center justify-center">
                  <div className="dot-flashing" />
                </div>
              }>
              {listDataRender?.map((ward) => {
                const isSelect = value.find((item) => item._id === ward._id);
                return (
                  <div
                    key={ward._id}
                    onClick={() => {
                      setValue(
                        isSelect
                          ? value?.filter((item) => item._id !== ward._id)
                          : [...value, ward]
                      );
                    }}
                    className="py-3 flex items-center hover:bg-[rgba(0,0,0,0.05)] duration-300 cursor-pointer ">
                    <i
                      className={clsx(
                        "mrv   text-fs-24 mr-2",
                        isSelect
                          ? "mrv-check_box text-blue-500"
                          : "mrv-check_box_outline_blank"
                      )}></i>
                    {isNumeric(ward?.name) ? ward.title : ward?.name}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
          <div className="flex py-2 px-3">
            <Button
              className="w-full flex justify-center !text-grey-600"
              btnType="link"
              onClick={() => {
                setValue([]);
              }}>
              Đặt lại{" "}
            </Button>
            <Button
              className="w-full flex justify-center"
              btnType="filled"
              onClick={() => {
                setValueLocation({
                  city: valueLocation.city,
                  district: valueLocation.district,
                  ward: value,
                });
                if (onConfirm)
                  onConfirm({
                    city: valueLocation.city,
                    district: valueLocation.district,
                    ward: value,
                  });
                else setActiveTab(null);
              }}>
              Xác nhận{" "}
            </Button>
          </div>
        </>
      }
    </>
  );
};

export default SelectCity;
