import Button from "@/components/Button";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { ValueLocation } from "@/models/location";
import axios from "axios";
import clsx from "clsx";
import { unionBy } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/* eslint-disable @next/next/no-img-element */
const SelectCity = ({
  valueLocation,
  setValueLocation,
  setActiveTab,
  onConfirm,
}: {
  valueLocation?: ValueLocation;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  setValueLocation?: Dispatch<SetStateAction<ValueLocation>>;
  onConfirm?: Dispatch<SetStateAction<ValueLocation>>;
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(valueLocation.city);
  }, [valueLocation]);
  const listDataRender = useMemo(() => {
    return unionBy(value, data, "_id")?.filter(
      (item) =>
        item?.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [data, search, value]);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const res = await axios.request({
        baseURL: MEEY_REVIEW_API_URL,
        url: "/v1/cities",
        params: {
          limit: 500,
        },
      });
      setData(res.data?.results);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className="flex gap-1 items-center mx-4 px-3 py-2 bg-[rgba(0,0,0,0.05)] rounded">
        <i className="mrv mrv-search text-fs-20" />
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value?.slice(0, 100))}
          placeholder="Tìm Tỉnh/Thành phố"
          className="h-[28px] mr-5 w-full text-base bg-transparent"
        />
      </div>

      {loading ? (
        <div className="w-full h-full flex justify-center py-4">
          <div className={"dot-flashing"}></div>
        </div>
      ) : (
        <>
          <div className="w-full h-full overflow-auto">
            <div
              onClick={() => {
                setValueLocation({
                  city: [],
                  district: [],
                  ward: [],
                });
              }}
              className="py-3 px-4 flex items-center hover:bg-[rgba(0,0,0,0.05)] duration-300 cursor-pointer border-b border-b-grey-200 ">
              <i
                className={clsx(
                  "mrv mrv-check text-fs-24 mr-2",
                  !value?.length ? "text-blue-500" : "text-transparent"
                )}></i>
              Toàn quốc
            </div>
            {listDataRender?.map((city) => (
              <div
                key={city._id}
                onClick={() => {
                  // setValueLocation({
                  //   city: [city],
                  //   district: [],
                  //   ward: [],
                  // });
                  setValue([city]);
                }}
                className="py-3 px-4 flex items-center hover:bg-[rgba(0,0,0,0.05)] duration-300 cursor-pointer border-b border-b-grey-200 ">
                <i
                  className={clsx(
                    "mrv mrv-check text-fs-24 mr-2",
                    value?.find((e) => e._id === city._id)
                      ? "text-blue-500"
                      : "text-transparent"
                  )}></i>
                {city?.name}
              </div>
            ))}
          </div>

          <div className="flex py-2 px-3">
            <Button
              className="w-full flex justify-center !text-grey-600 !h-10 foucus:!border-tranparent hover:!border-transparent"
              btnType="link"
              onClick={() => {
                setValue([]);
              }}>
              Đặt lại{" "}
            </Button>
            <Button
              className="w-full flex justify-center !h-10"
              btnType="filled"
              onClick={() => {
                setValueLocation({
                  city: value,
                  district: [],
                  ward: [],
                });
                if (onConfirm) {
                  onConfirm({
                    city: value,
                    district: [],
                    ward: [],
                  });
                } else if (value.length) setActiveTab("district");
                else setActiveTab(null);
              }}>
              Xác nhận{" "}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SelectCity;
