import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import REQUEST_METHOD from "@/constants/request-method";
import { BaseDataListResponse } from "Models";
import urlJoin from "url-join";

export const saveSearchHistory = async (params: any, headers?: any) => {
  const path = urlJoin(`v1/projects/savehistory`);

  const response = await createFetcher<BaseDataListResponse<any>>(() => ({
    apiUrl: MEEY_REVIEW_API_URL,
  }))(REQUEST_METHOD.POST, path, params, headers);

  return response;
};
