import PopOver from "@/components/PopOver";
import { DEFAULT_IMAGE_CARD } from "@/constants/common-constant";
import { getRelativeTime } from "@/utils/datetime";
import { IMeeyImageProps } from "Models";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import AvatarWithName from "./AvatarWithName";

interface IUserTime {
  src: string;
  alt?: string;
  name?: string;
  subTitle?: string;
  titleClass?: string;
  textClass?: string;
  subTitleClass?: string;
  imageClass?: string;
  className?: string;
  imageProps?: IMeeyImageProps;
  size?: any;
  time?: string;
  haveAction?: boolean;
  actionTitle?: string;
  actionIcon?: ReactNode;
  aboutProject?: ReactNode;
  maxLengthName?: number;
}
const UserTime = ({
  src,
  alt,
  name,
  subTitle,
  textClass,
  className,
  subTitleClass,
  imageClass,
  imageProps,
  size,
  time,
  haveAction = false,
  actionTitle,
  actionIcon,
  aboutProject,
  maxLengthName,
  ...otherProps
}: IUserTime) => {
  const textSize = size === "small" ? "text-fs-14" : "text-base";
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <div className={clsx("flex items-center", className)}>
      <div className="w-max shrink-0">
        <AvatarWithName
          imageClass={clsx(imageClass ? imageClass : "!w-6 !h-6")}
          imageProps={{ resizeOnFly: false }}
          className="flex-0 "
          avatarOnly
          alt={name ?? "Ẩn danh"}
          name={name}
          src={src ? src : name ? null : DEFAULT_IMAGE_CARD}
        />
        {/* <Avatar
          className="border-none"
          // eslint-disable-next-line react/no-children-prop
          children={<>{getAvatarName(name)}</>}
          size={size}
          src={src}
          style={{ ...styleColor, fontSize: 14 }}
          {...otherProps}></Avatar> */}
      </div>

      {haveAction ? (
        name || time ? (
          <div className="ml-1 flex flex-col ">
            <div className="flex items-center gap-1.5 flex-wrap">
              {maxLengthName && name?.length > maxLengthName ? (
                <PopOver
                  trigger="hover"
                  dropdownClassName="mt-2 !p-0 rounded !bg-transparent"
                  content={
                    <div className="py-1 px-2 bg-black-800 text-fs-14 text-white max-w-[30rem] break-word">
                      {name}
                    </div>
                  }
                  position="top-left">
                  <span
                    className={clsx(
                      "font-semibold break-word",
                      textSize,
                      textClass
                    )}>
                    {name.substring(0, maxLengthName) + "..."}
                  </span>
                </PopOver>
              ) : (
                <span
                  className={clsx(
                    "font-semibold break-word",
                    textSize,
                    textClass
                  )}>
                  {name}
                </span>
              )}
              <div className="text-grey-600 text-fs-14 shrink-0">
                {actionTitle}
              </div>
              {actionIcon}
              {aboutProject}
            </div>
            <div className="text-grey-400 flex-0 text-fs-12">
              {mounted ? getRelativeTime(time) : null}
            </div>
          </div>
        ) : null
      ) : name || time ? (
        <div className="flex items-center gap-2">
          {maxLengthName && name?.length > maxLengthName ? (
            <PopOver
              trigger="hover"
              dropdownClassName="mt-2 !p-0 rounded !bg-transparent"
              content={
                <div className="py-1 px-2 bg-black-800 text-fs-14 text-white max-w-[30rem] break-word">
                  {name}
                </div>
              }
              position="top-left">
              <span
                className={clsx(
                  "font-semibold ml-2 break-word ",
                  textSize,
                  textClass
                )}>
                {name.substring(0, maxLengthName) + "..."}
              </span>
            </PopOver>
          ) : (
            <span
              className={clsx(
                "font-medium md:font-semibold ml-2 break-word line-clamp-1 flex-1",
                textSize,
                textClass
              )}>
              {name}
            </span>
          )}

          <div className="w-[5px] h-[5px] rounded-full bg-grey-400 flex-0"></div>
          <div className="text-grey-400 flex-0 text-fs-12 md:text-fs-14">
            {mounted ? getRelativeTime(time) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserTime;
