import MeeyImage from "@/components/MeeyImage";
import VideoPlayer from "@/components/VideoPlayer";
import { fileType } from "@/constants/file";
import { IMediaFile } from "Models";
import clsx from "clsx";
const MAX_FILE_SHOW = 7;
const ListFileReview = ({
  data,
  className,
  maxFileShow = MAX_FILE_SHOW,
}: {
  data: IMediaFile;
  className?: string;
  enableShowFull?: boolean;
  maxFileShow?: number;
}) => {
  return data.media?.length ? (
    <div
      key={data._id}
      className={clsx(
        "py-2 rounded-lg text-fs-12 text-grey-600 flex overflow-auto flex-wrap gap-2 w-full",
        className
      )}
    >
      {data.media?.slice(0, maxFileShow + 1).map((file, id) => (
        <div
          key={file.s3Key}
          className={clsx(
            "relative group cursor-pointer",
            file.name.match(fileType.image) || file.name.match(fileType.video)
              ? "can-preview-upload"
              : "default-file"
          )}
        >
          {file.name.match(fileType.image) ? (
            <div className="w-[3rem] h-[3rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
              <div className="  text-fs-12 w-full h-full overflow-hidden  rounded">
                <MeeyImage
                  className="object-cover w-full h-full"
                  src={file.uri}
                  alt={file.name}
                  resizeOnFly={!!file?.uri}
                  resizeOptions={{
                    width: 48,
                    height: 48,
                  }}
                />
              </div>
            </div>
          ) : file.name.match(fileType.video) ? (
            <div className="w-[3rem] h-[3rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
              <div className="  text-fs-12 w-full h-full overflow-hidden  rounded-md">
                <VideoPlayer
                  options={{
                    autoplay: false,
                    controls: false,
                    responsive: true,
                    fluid: true,
                    preload: "metadata",
                    sources: [
                      {
                        src: file.urlVod,
                      },
                    ],
                  }}
                />
                <div className="absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2">
                  <i className="mrv mrv-play_arrow text-white text-fs-24 font-bold"></i>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-[3rem] h-[3rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer border border-transparent border-dashed">
              <div className="flex-[0_0_28px] w-full flex items-center">
                <div className="-rotate-90 w-fit flex justify-center items-center">
                  <i className="mrv mrv-attachment text-fs-20 text-grey-600"></i>
                </div>
                <div className="text-fs-10 text-grey-600">
                  .{file.name.split(".")?.pop()}
                </div>
              </div>
              <div className="bg-grey-300 flex-[1_1_auto]  text-fs-12 py-1.5 px-2 overflow-hidden  rounded-b-md"></div>
            </div>
          )}
          {}
          {id === maxFileShow && data.media.length > maxFileShow + 1 ? (
            <div className="cursor-pointer absolute w-full h-full top-0 bg-[rgba(0,0,0,0.2)] flex items-center justify-center z-10   rounded-md duration-300 text-base font-semibold text-white">
              +{data.media.length - (maxFileShow + 1)}
            </div>
          ) : (
            <div className="cursor-pointer absolute w-full h-full top-0 z-10 bg-[rgba(0,0,0,0.4)] flex items-center justify-center opacity-0 group-hover:opacity-100  rounded-md duration-300">
              <i className="mrv mrv-preview text-white text-fs-20"></i>
            </div>
          )}
        </div>
      ))}
    </div>
  ) : null;
};

export default ListFileReview;
